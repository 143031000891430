import React from 'react';
import { Modal, Button, FileUpload, Alert, Field, XFilledIcon } from 'react-unity';
import CSVGeneratorButton from '../../common/CSVGeneratorButton';
import { AppColumnsLayout, AppColumnsExample, AppColumn } from './AppColumnsLayout';

interface BulkUploadModalProps {
	onSuccess: (apps: []) => any;
	onCancel: (...args: any[]) => any;
	visible: boolean;
	applicationService: any;
}

interface BulkUploadModalState {
	loading: boolean;
	file: any;
	alert: {
		visible: boolean;
		text?: string;
	},
	templateColumns: AppColumn[];
	exampleColumn: any;
}

export default class BulkUploadModal
	extends React.Component<BulkUploadModalProps, BulkUploadModalState> {
	
	applicationService: any;

	constructor(props: BulkUploadModalProps) {
		super(props);

		this.applicationService = this.props.applicationService;

		this.state = {
			loading: false,
			file: null,
			alert: {
				visible: false,
			},
			templateColumns: AppColumnsLayout,
			exampleColumn: AppColumnsExample
		};
	}

	handleChange = (file) => {
		this.setState({
			file: file[0],
			alert: {
				visible: false,
			}
		});
	};

	handleUpload = async () => {
		this.setState({
			loading: true
		});

		this.applicationService.parse(this.state.file)
			.then((response: []) => {
				this.props.onSuccess(response);
				// TODO: reset input on success or cancellation
			})
			.catch(err => {
				this.setState({
					alert: {
						visible: true,
						text: err.message
					}
				});
			})
			.finally(() => {
				this.setState({
					loading: false,
					alert: {
						visible: false
					},
					file: null
				});
			});
	};

	render() {
		return (
			<Modal show={this.props.visible}>
				<Modal.Window>
					<Modal.Window.Header>
						<Modal.Window.Header.Title>
							Import applications to the request
						</Modal.Window.Header.Title>
						<Modal.Window.Header.CloseButton onClick={this.props.onCancel}>
							<XFilledIcon size="small" />
						</Modal.Window.Header.CloseButton>
					</Modal.Window.Header>
					<Modal.Window.Body>
						<Field>
							<CSVGeneratorButton
								label="Download Template"
								columns={this.state.templateColumns ?? []}
								fileName={`Template-Applications-To-Import.csv`}
								data={this.state.exampleColumn}
							/>
						</Field>
						<FileUpload
							multiple={false}
							onChange={this.handleChange}
						/>
						{this.state.alert.visible &&
							<Alert
								variant="error"
								onClose={() => {
									this.setState({
										alert: {
											visible: false,
										}
									});
								}}
							>
								{this.state.alert.text}
							</Alert>}
					</Modal.Window.Body>
					<Modal.Window.Footer>
						<Button
							variant="primary"
							onClick={this.handleUpload}
							loading={this.state.loading}
							disabled={(!this.state.file) || this.state.alert.visible}
						>
							Preview Applications
						</Button>
					</Modal.Window.Footer>
				</Modal.Window>
			</Modal>
		);
	}
}