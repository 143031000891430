import moment from 'moment';
import React from 'react';
import { Badge, Button, MediaBlock, Table } from 'react-unity';
import SubscriptionRequest from '../../../models/entities/SubscriptionRequest';
import ActionRow from '../../common/tables/ActionRow';
import WorkflowStateBadge from '../../common/WorkflowStateBadge';
import './SubscriptionHomeTableRow.css';
import { useNavigate } from 'react-router-dom';
import SubscriptionsHomeActionsRow from './SubscriptionsHomeActionsRow';
import { Tooltip } from 'react-tooltip';

interface SubscriptionHomeTableRowProps {
	subscription: SubscriptionRequest;
	seePermissionsReport: (sub: SubscriptionRequest) => void;
	SubscriptionRowRef: React.RefObject<HTMLBaseElement>;
	editMultiple: boolean;
	setCheckBoxState: any;
	checkBoxState: number;
	setModalVisibleDomainOperation: (visible: boolean) => void;
	setSubId: (subId: number) => void;
	setSubType: (subType: boolean) => void;
}
const connectedIcon = <path fill="currentColor" d="M 24.875 0 A 1.0001 1.0001 0 0 0 24.28125 0.28125 L 19.625 4.9375 C 17.42105 3.3463983 14.363638 3.6317919 12.3125 5.59375 C 12.3125 5.6701204 11.457295 6.5035041 10.6875 7.25 L 9.71875 6.28125 A 1.0001 1.0001 0 0 0 8.78125 6 A 1.0001 1.0001 0 0 0 8.28125 7.71875 L 18.28125 17.71875 A 1.016466 1.016466 0 1 0 19.71875 16.28125 L 18.75 15.3125 L 20.40625 13.6875 C 22.366628 11.727122 22.564583 8.6795387 21.03125 6.40625 L 25.71875 1.71875 A 1.0001 1.0001 0 0 0 24.875 0 z M 6.90625 7.96875 A 1.0001 1.0001 0 0 0 6.78125 8 A 1.0001 1.0001 0 0 0 6.28125 9.71875 L 7.25 10.6875 L 5.59375 12.3125 C 3.633372 14.272878 3.4354172 17.320461 4.96875 19.59375 L 0.28125 24.28125 A 1.016466 1.016466 0 1 0 1.71875 25.71875 L 6.375 21.0625 C 8.5789499 22.653602 11.636362 22.368208 13.6875 20.40625 C 13.6875 20.32988 14.542705 19.496496 15.3125 18.75 L 16.28125 19.71875 A 1.016466 1.016466 0 1 0 17.71875 18.28125 L 7.71875 8.28125 A 1.0001 1.0001 0 0 0 6.90625 7.96875 z" />
const disconnectedIcon = <path fill="currentColor" d="M 24.875 0 A 1.0001 1.0001 0 0 0 24.28125 0.28125 L 22.625 1.9375 C 20.42105 0.3463983 17.363638 0.63179191 15.3125 2.59375 C 15.3125 2.6701204 14.457295 3.5035041 13.6875 4.25 L 12.71875 3.28125 A 1.0001 1.0001 0 0 0 11.90625 2.96875 A 1.0001 1.0001 0 0 0 11.78125 3 A 1.0001 1.0001 0 0 0 11.28125 4.71875 L 13.5625 7 L 10.28125 10.28125 A 1.016466 1.016466 0 1 0 11.71875 11.71875 L 15 8.4375 L 17.5625 11 L 14.28125 14.28125 A 1.016466 1.016466 0 1 0 15.71875 15.71875 L 19 12.4375 L 21.28125 14.71875 A 1.016466 1.016466 0 1 0 22.71875 13.28125 L 21.75 12.3125 L 23.40625 10.6875 C 25.366628 8.727122 25.564583 5.6795387 24.03125 3.40625 L 25.71875 1.71875 A 1.0001 1.0001 0 0 0 24.875 0 z M 3.90625 10.96875 A 1.0001 1.0001 0 0 0 3.78125 11 A 1.0001 1.0001 0 0 0 3.28125 12.71875 L 4.25 13.6875 L 2.59375 15.3125 C 0.63337202 17.272878 0.43541722 20.320461 1.96875 22.59375 L 0.28125 24.28125 A 1.016466 1.016466 0 1 0 1.71875 25.71875 L 3.375 24.0625 C 5.5789499 25.653602 8.636362 25.368208 10.6875 23.40625 C 10.6875 23.32988 11.542705 22.496496 12.3125 21.75 L 13.28125 22.71875 A 1.016466 1.016466 0 1 0 14.71875 21.28125 L 4.71875 11.28125 A 1.0001 1.0001 0 0 0 3.90625 10.96875 z" />

const SubscriptionHomeTableRow = ({
	subscription,
	seePermissionsReport,
	SubscriptionRowRef,
	editMultiple,
	setCheckBoxState,
	checkBoxState,
	setModalVisibleDomainOperation,
	setSubId,
	setSubType
}: SubscriptionHomeTableRowProps) => {
	const navigate = useNavigate();
	const handleViewDetails = () => {
		navigate(`/subscriptions/${subscription.id}`);
	};

	// Update List Item's state and Master Checkbox State
	const onItemCheck = (e, subscription) => {
		subscription.setEditable(e.target.checked);
		e.target.checked ? setCheckBoxState(checkBoxState += 1) : setCheckBoxState(checkBoxState -= 1);
	};

	return (
		<tr>
			{editMultiple &&
				<Table.Body.Cell>
					<input
						type="checkbox"
						id={subscription.id.toString()}
						value={subscription.id.toString()}
						onChange={(e) => onItemCheck(e, subscription)}
					/>
				</Table.Body.Cell>
			}
			<ActionRow
				key={subscription.id}
				actionRef={SubscriptionRowRef}
				actionCell={
					<span data-tip={subscription.createdSubscriptionName} className="table__cell-name">
						{subscription.createdSubscriptionName}
					</span>
				}
				actions={
					<SubscriptionsHomeActionsRow subscription={subscription} seePermissionsReport={seePermissionsReport} setSubId={setSubId} setSubType={setSubType} setModalVisibleDomainOperation={setModalVisibleDomainOperation} />
				}
			/>
			<Table.Body.Cell>
				<svg data-tip={`${subscription.isConnected ? 'Connected' : 'Disconnected'} Subscription`} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 26 26" enableBackground="new 0 0 26 26" className=" em-c-icon em-c-icon--small em-c-icon_svg ">

					{subscription.isConnected ? connectedIcon : disconnectedIcon}
				</svg>
			</Table.Body.Cell>
			<Table.Body.Cell>
				<WorkflowStateBadge
					workflowState={subscription.workflowInstance.currentWorkflowInstanceState.workflowState}
					fullWidth
				/>
			</Table.Body.Cell>
			<Table.Body.Cell>
				<MediaBlock size="tiny">
					<Tooltip place="top" />
					<MediaBlock.Body className='em-u-text-align-center'>
						{subscription.organization.L4 && subscription.organization.L3 ? (
							<>
								<MediaBlock.Body.Description className="table__cell-org ">
								 	{subscription.organization.L3.shortName ?? subscription.organization.L3.displayName}
								</MediaBlock.Body.Description>
								<MediaBlock.Body.Headline data-tip={subscription.organization.L3.displayName} className="table__cell-org ">
									{subscription.organization.L4.displayName}

								</MediaBlock.Body.Headline>
							</>
						) : (
							<MediaBlock.Body.Headline>No organization</MediaBlock.Body.Headline>
						)}
					</MediaBlock.Body>
				</MediaBlock>
			</Table.Body.Cell>
			<Table.Body.Cell className='em-u-text-align-center'>
				<span data-tip={subscription.owner.displayName} className='table__cell'>
					{subscription.owner.displayName}
				</span>
			</Table.Body.Cell>
			<Table.Body.Cell className='em-u-text-align-center'>
				<span data-tip={subscription.custodian.displayName} className='table__cell'>
					{subscription.custodian.displayName}
				</span>
			</Table.Body.Cell>
			<Table.Body.Cell className='em-u-text-align-center'>
				<span data-tip={subscription.custodianTwo?.displayName} className="table__cell">
					{subscription.custodianTwo?.displayName ?? '-'}
				</span>
			</Table.Body.Cell>
			<Table.Body.Cell>
				<Badge className="em-u-width-100">{subscription.azureState}</Badge>
			</Table.Body.Cell>
			<Table.Body.Cell className='em-u-text-align-center'>{moment(subscription.workflowInstance.createdDate).format('MM/DD/YYYY')}</Table.Body.Cell>
			<Table.Body.Cell>
				<Button style={{ width: 120, height: 34 }} onClick={handleViewDetails} variant="primary">
					See Details
				</Button>
			</Table.Body.Cell>
		</tr>
	);
};

export default SubscriptionHomeTableRow;
